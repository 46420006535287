import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import Php from "../../../Backend/Php";
import {
  BalanceContainer,
  BalanceLabel,
  CreateClientButton,
} from "../../Common/Common";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";

const php = new Php();
const height = window.innerHeight;

export default function AddCustomerModal({
  setVisible,
  visible,
  position,
  reload,
}) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [customerType, setCustomerType] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    type: "",
    quotation_no: "",
    details: "",
  });

  const addCustomer = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        customer_name: formData?.name,
        customer_mobile: formData?.mobile,
        customer_address: formData?.address,
        customer_city: formData?.city,
        customer_district: formData?.district,
        customer_state: formData?.state,
        customer_pincode: formData?.pincode,
        alt_mobile: formData?.altMobile,
        alt_mobile_2: formData?.altMobile2,
        customer_area: formData?.area,
        customer_new_old: customerType == true ? 1 : 0,
      };
      php.add_customer(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setVisible();
          reload();
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formFields = [
    {
      label: "Quotation Name",
      name: "name",
      type: "text",
    },
    {
      label: "Quotation Type",
      name: "type",
      type: "text",
    },
    {
      label: "Quotation details",
      name: "details",
      type: "text",
    },
  ];

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        icon="pi pi-plus"
        severity="info"
        onClick={() => addCustomer()}
        label={"Customer"}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );

  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>{"Add Quotation "}</div>
      }
      visible={visible}
      position={position}
      style={{ width: "80vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      {formFields.map((field) => (
        <BalanceContainer key={field.name}>
          <BalanceLabel>{field.label}</BalanceLabel>
          {field.type === "textarea" ? (
            <InputTextarea
              style={{ width: "50%", height: "80px" }}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
            />
          ) : (
            <InputText
              style={{ width: "50%", height: "40px" }}
              type={field.type}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
            />
          )}
        </BalanceContainer>
      ))}

      <BalanceContainer>
        <BalanceLabel>{"Status"}</BalanceLabel>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "50%",
          }}
        >
          <CreateClientButton
            active={!customerType}
            color={NewTheme.MainColor}
            onClick={() => setCustomerType(false)}
          >
            {"Active"}
          </CreateClientButton>
          <CreateClientButton
            active={customerType}
            color={NewTheme.MainColor}
            onClick={() => setCustomerType(true)}
          >
            {"Inactive"}
          </CreateClientButton>
        </div>
      </BalanceContainer>
    </Dialog>
  );
}
