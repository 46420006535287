import React from "react";

const TotalDepositCard = ({ title, totalAmount, count }) => {
  // Dynamic color based on the total amount
  const getBackgroundColor = (amount) => {
    if (amount < 0) return "#ffcccc"; // Light red
    if (amount >= 0) return "#ccffcc"; // Light yellow
    return "#ccffcc"; // Light green
  };

  const cardStyle = {
    ...styles.card,
    backgroundColor: getBackgroundColor(totalAmount),
  };

  return (
    <div style={cardStyle}>
      {title && <h2 style={styles.header}>{title}</h2>}
      <p style={styles.amount}>${totalAmount.toLocaleString()}</p>
      {count > 0 && (
        <p style={styles.count}>Approved : {count.toLocaleString()}</p>
      )}
    </div>
  );
};

const styles = {
  card: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "15px",
    minWidth: "250px",
    maxWidth: "250px",
    margin: "10px auto",
    textAlign: "center",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease",
  },
  header: {
    marginBottom: "16px",
    fontSize: "20px",
    color: "#333",
  },
  amount: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#007bff",
  },
  count: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "black",
  },
};

export default TotalDepositCard;
