import React from "react";

import TopbarMost from "../Common/TopbarMost";
import { NewTheme } from "../Theme/Theme";

import Php from "../../Backend/Php";

import moment from "moment";
import HomeTable from "./HomeTable";
import TotalDepositCard from "./Card";
import { Calendar } from "primereact/calendar";
import { CreateClientButton } from "../Common/Common";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 95;

export default function Home() {
  const [dashboardData, setDashboardData] = React.useState([]);

  const initialShowNotification =
    JSON.parse(localStorage.getItem("showNotification")) || false;
  const [showNotification, setShowNotification] = React.useState(
    initialShowNotification
  );

  const [deposit, setDeposit] = React.useState(107789);
  const [depositCount, setDepositCount] = React.useState(0);
  const [depositBank, setDepositBank] = React.useState(0);
  const [depositSite, setDepositSite] = React.useState(0);
  // const [fromDate, setFromDate] = React.useState(new Date());
  // const [toDate, setToDate] = React.useState(new Date());
  const [withdraw, setWithdraw] = React.useState(0);
  const [withdrawCount, setWithdrawCount] = React.useState(0);
  const [withdrawBank, setWithdrawBank] = React.useState(0);
  const [withdrawSite, setWithdrawSite] = React.useState(0);

  const now = moment();
  const fiveThirty = moment().hour(5).minute(30);

  let initialFromDate, initialToDate;

  if (now.isAfter(fiveThirty)) {
    initialFromDate = moment(fiveThirty).toDate();
    initialToDate = moment(fiveThirty).add(1, "day").toDate();
  } else {
    initialFromDate = moment(fiveThirty).subtract(1, "day").toDate();
    initialToDate = moment(fiveThirty).toDate();
  }

  // Initialize state variables with calculated dates
  const [fromDate, setFromDate] = React.useState(initialFromDate);
  const [toDate, setToDate] = React.useState(initialToDate);

  // React.useEffect(() => {
  //   // Save showNotification to localStorage whenever it changes
  //   localStorage.setItem("showNotification", JSON.stringify(showNotification));
  // }, [showNotification]);

  React.useEffect(() => {
    // loadDashboard();
  }, []);

  const loadDashboard = () => {
    let data = {
      token: localStorage.getItem("token"),
      fromdate: moment(fromDate).format("YYYY-MM-DD HH:mm:00"),
      todate: moment(toDate).format("YYYY-MM-DD HH:mm:00"),
    };
    // console.log(data);
    php.load_dashboard(data).then((r) => {
      // console.log(r);
      if (r.error == "False") {
        setDeposit(r.deposit);
        setDepositCount(r.deposit_count);
        setWithdrawCount(r.withdraw_count);
        setDepositBank(r.deposit_bank);
        setDepositSite(r.deposit_site);
        setWithdraw(r.withdraw);
        setWithdrawBank(r.withdraw_bank);
        setWithdrawSite(r.withdraw_site);
      } else {
        alert(r.message);
        if (r.error == "logout") {
          logoutFunc();
        }
      }
    });
  };

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const topworkui = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 30,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 22 }}>Today Total :</div>
        <div
          style={{
            fontSize: 22,
            fontWeight: "bold",
            color: NewTheme.greencolor,
          }}
        >
          {parseFloat(deposit).toFixed(0)}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 30,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 22 }}>From Date :</div>
        <div
          style={{
            fontSize: 22,
            fontWeight: "bold",
            color: NewTheme.greencolor,
          }}
        >
          <Calendar
            style={{
              width: "80%",
              height: "40px",
            }}
            hourFormat="24"
            showTime
            id="calendar-12h"
            dateFormat="dd/mm/yy"
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 30,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 22 }}>To Date :</div>
        <div
          style={{
            fontSize: 22,
            fontWeight: "bold",
            color: NewTheme.greencolor,
          }}
        >
          <Calendar
            style={{
              width: "80%",
              height: "40px",
            }}
            hourFormat="24"
            showTime
            id="calendar-12h"
            dateFormat="dd/mm/yy"
            value={toDate}
            onChange={(e) => setToDate(e.value)}
          />
        </div>
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <CreateClientButton
          greencolor
          style={{
            height: 30,
            marginRight: 30,
          }}
          onClick={() => loadDashboard()}
        >
          Filter
        </CreateClientButton>
      </div>
    </div>
  );

  return (
    <div>
      <TopbarMost name={" | HOME "} cmp={topworkui} />
      <HomeTable />
      {/* <div style={{ display: "flex" }}>
        <TotalDepositCard
          title={"Total Deposit"}
          totalAmount={deposit}
          count={depositCount}
        />
        <TotalDepositCard
          title={"Total Withdraw"}
          totalAmount={withdraw}
          count={withdrawCount}
        />
        <TotalDepositCard
          title={"Total Diff"}
          totalAmount={
            parseFloat(deposit).toFixed(2) - parseFloat(withdraw).toFixed(2)
          }
        />
      </div>
      <div style={{ display: "flex" }}>
        <TotalDepositCard
          title={"Total Deposit Bank"}
          totalAmount={depositBank}
        />
        <TotalDepositCard
          title={"Total Withdraw Bank"}
          totalAmount={withdrawBank}
        />
        <TotalDepositCard
          title={"Total Diff Bank"}
          totalAmount={
            parseFloat(depositBank).toFixed(2) -
            parseFloat(withdrawBank).toFixed(2)
          }
        />
      </div>
      <div style={{ display: "flex" }}>
        <TotalDepositCard
          title={"Total Deposit Site"}
          totalAmount={depositSite}
        />
        <TotalDepositCard
          title={"Total Withdraw Site"}
          totalAmount={withdrawSite}
        />
        <TotalDepositCard
          title={"Total Diff Site"}
          totalAmount={
            parseFloat(depositSite).toFixed(2) -
            parseFloat(withdrawSite).toFixed(2)
          }
        />
      </div> */}
    </div>
  );
}
