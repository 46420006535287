import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import Php from "../../../Backend/Php";
import {
  BalanceContainer,
  BalanceLabel,
  CreateClientButton,
} from "../../Common/Common";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";

const php = new Php();
const height = window.innerHeight;

export default function EditCustomerModal({
  passData,
  setVisible,
  visible,
  position,
  reload,
}) {
  console.log(passData);
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [customerType, setCustomerType] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: passData?.name,
    mobile: passData?.mobile,
    altMobile: passData?.alt_mobile,
    altMobile2: passData?.alt_mobile_2,
    address: passData?.address,
    area: passData?.area,
    city: passData?.city,
    district: passData?.district,
    state: passData?.state,
    pincode: passData?.pin_code,
    remarks: "",
  });

  const editCustomer = (e) => {
    if (passData?.id == undefined) {
      alert("Invalid Customer. Please reload and try again!!");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          token: localStorage.getItem("token"),
          customer_name: formData?.name,
          customer_mobile: formData?.mobile,
          customer_address: formData?.address,
          customer_city: formData?.city,
          customer_district: formData?.district,
          customer_state: formData?.state,
          customer_pincode: formData?.pincode,
          alt_mobile: formData?.altMobile,
          alt_mobile_2: formData?.altMobile2,
          customer_area: formData?.area,
          edit_new_old: customerType == true ? 1 : 0,
          edit_remarks: formData?.remarks,
          edit_id: passData?.id,
        };
        php.edit_customer(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            setVisible();
            reload();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const deleteCustomer = (e) => {
    if (passData?.id == undefined) {
      alert("Invalid Customer. Please reload and try again!!");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          token: localStorage.getItem("token"),
          id: passData?.id,
        };
        php.delete_customer(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            setVisible();
            reload();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formFields = [
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "e.g. john david",
    },
    {
      label: "Mobile",
      name: "mobile",
      type: "number",
      placeholder: "e.g. 9090990990",
    },
    {
      label: "Alternate Mobile",
      name: "altMobile",
      type: "number",
      placeholder: "(Optional)",
    },
    {
      label: "Alternate Mobile 2",
      name: "altMobile2",
      type: "number",
      placeholder: "(Optional)",
    },
    {
      label: "Address",
      name: "address",
      type: "textarea",
      placeholder: "e.g. 12/A, ABC Street, Opp XYZ",
    },
    { label: "Area", name: "area", type: "text", placeholder: "e.g. Area A" },
    { label: "City", name: "city", type: "text", placeholder: "e.g. City A" },
    {
      label: "District",
      name: "district",
      type: "text",
      placeholder: "e.g. District A",
    },
    {
      label: "State",
      name: "state",
      type: "text",
      placeholder: "e.g. State A",
    },
    {
      label: "Pin Code",
      name: "pincode",
      type: "number",
      placeholder: "e.g. 370001",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "text",
      placeholder: "e.g. note",
    },
  ];

  const footerContent = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        label="Delete"
        icon="pi pi-trash"
        onClick={() => deleteCustomer()}
        className="p-button-text"
        style={{ backgroundColor: NewTheme.redcolor, color: "white" }}
      />
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() => setVisible(false)}
          className="p-button-text"
          style={{ color: NewTheme.MainColor }}
        />
        <Button
          icon="pi pi-pencil"
          severity="info"
          onClick={() => editCustomer()}
          label={"Update"}
          style={{ backgroundColor: NewTheme.MainColor }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>{"Edit Customer "}</div>
      }
      visible={visible}
      position={position}
      style={{ width: "80vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      {formFields.map((field) => (
        <BalanceContainer key={field.name}>
          <BalanceLabel>{field.label}</BalanceLabel>
          {field.type === "textarea" ? (
            <InputTextarea
              style={{ width: "50%", height: "80px" }}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
            />
          ) : (
            <InputText
              style={{ width: "50%", height: "40px" }}
              type={field.type}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
            />
          )}
        </BalanceContainer>
      ))}

      <BalanceContainer>
        <BalanceLabel>{"Customer Type"}</BalanceLabel>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "50%",
          }}
        >
          <CreateClientButton
            active={!customerType}
            color={NewTheme.MainColor}
            onClick={() => setCustomerType(false)}
          >
            {"OLD"}
          </CreateClientButton>
          <CreateClientButton
            active={customerType}
            color={NewTheme.MainColor}
            onClick={() => setCustomerType(true)}
          >
            {"NEW"}
          </CreateClientButton>
        </div>
      </BalanceContainer>
    </Dialog>
  );
}
