import React, { useContext } from "react";
import { createContext } from "react";
import { useNotification } from "../Notification/NotificationContext";
import Php from "../../Backend/Php";
import { loginApi } from "../../Axios/apis";
const php = new Php();

const UserContext = createContext({
  handleForm: () => {},
});
export const useUserContext = () => useContext(UserContext);
export default function LoginProvider({ children }) {
  const { addNotification } = useNotification();

  const [formData, setFormData] = React.useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);

  const handleInputs = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    if (formData.username == "admin" && formData.password == "12345") {
      window.location.href = "/Home";
      localStorage.setItem("isLoggedIn", "1");
    }
    // const data = {
    //   username: formData.username,
    //   password: formData.password,
    // };
    // php.login(data).then((r) => {
    //   if (r.error == "False") {
    //     addNotification(r.message, "success");
    //     localStorage.setItem("isLoggedIn", "1");
    //     localStorage.setItem("admin_data", JSON.stringify(r.data));
    //     localStorage.setItem("token", r.token);

    //     window.location.href = "/Home";
    //   } else {
    //     addNotification(r.message, "error");
    //   }
    // });
  };

  return (
    <UserContext.Provider
      value={{
        formData,
        loading,
        setLoading,
        handleInputs,
        handleLogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
