// theme.js

export const NewTheme = {
  MainColor: "#4a6ba1",
  MainColorExcel: "#4a6ba1",
  greencolor: "#019c6b",
  greencolorExcel: "#019c6b",
  redcolor: "#df1524",
  redcolorExcel: "#df1524",
  lightmain: "#edebfa",
  newredtext: "#DC143C",
  lightred: "#f7babe3b",
};
