import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Php from "../../Backend/Php";
import { NewTheme } from "../Theme/Theme";
import { useNotification } from "../Notification/NotificationContext";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useWindowSize } from "@react-hook/window-size";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import * as ExcelJS from "exceljs";

const php = new Php();
const height = window.innerHeight;

export default function HistoryTable({
  tableData,
  reload,
  loading,
  selectedUser,
  setSelectedUser,
  usersFilter,
  date,
  setDate,
  onSubmit,
  selectedBank,
  setSelectedBank,
  banksFilter,
  setUtrNo,
  utrNo,
  selectedSite,
  setSelectedSite,
  sitesFilter,
}) {
  const { addNotification } = useNotification();
  const [width, height] = useWindowSize();

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const delete_entry = (id) => {
    if (window.confirm("Do you really want to delete this entry?")) {
      if (id <= 0) {
        alert("Invalid entry. Please reload !!");
      } else {
        if (!loading) {
          let data = {
            token: localStorage.getItem("token"),
            entry_id: id,
          };
          php.delete_entry(data).then((r) => {
            if (r.error === "False") {
              reload();
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    }
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "id",
      "admin_name",
      "user_name",
      "service_type",
      "previous_date",
      "service_date",
      "type2",
      "amount",
      "date_created",
      "remarks",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "ID",
      "Admin",
      "Customer",
      "Service",
      "Previous Date",
      "Service Date",
      "Type",
      "Amount",
      "Date Created",
      "Remarks",
    ];

    const headerRow = worksheet
      .addRow([
        JSON.parse(
          localStorage.getItem("admin_data")
        )?.white_label.toUpperCase() +
          " HISTORY FROM " +
          moment(date[0]).format("DD-MMM-YY ") +
          " TO " +
          moment(date[1]).format("DD-MMM-YY "),
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:E${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    const headerDisplayNamesRow = worksheet.addRow(headerDisplayNames);

    // Make the header row bold
    headerDisplayNamesRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Extract only the desired columns from tableData
    const filteredData = tableData.map((item) => {
      return colsToExport.reduce((acc, col) => {
        acc[col] = item[col];
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });
    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    var fileName =
      JSON.parse(
        localStorage.getItem("admin_data")
      )?.white_label.toUpperCase() +
      " HISTORY FROM " +
      moment(date[0]).format("DD-MMM-YY ") +
      " TO " +
      moment(date[1]).format("DD-MMM-YY ");

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      <div
        style={{
          height: 40,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 20,
          fontWeight: "bold",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Dropdown
              style={{
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                minWidth: 200,
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedSite(e.value);
                } else {
                  setSelectedSite(e.value);
                }
              }}
              value={selectedSite}
              options={sitesFilter}
              optionLabel="site_name"
              placeholder="Select Site"
              filter
            />
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Dropdown
              style={{
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                minWidth: 200,
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedBank(e.value);
                } else {
                  setSelectedBank(e.value);
                }
              }}
              value={selectedBank}
              options={banksFilter}
              optionLabel="bank_name"
              placeholder="Select Bank"
              filter
            />
          </div>

          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Dropdown
              style={{
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                minWidth: 200,
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedUser(e.value);
                } else {
                  setSelectedUser(e.value);
                }
              }}
              value={selectedUser}
              options={usersFilter}
              optionLabel="username"
              placeholder="Select Admin"
              filter
            />
          </div>

          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Calendar
              style={{ width: 230 }}
              selectionMode="range"
              id="calendar-12h"
              dateFormat="dd/mm/yy"
              value={date}
              onChange={(e) => setDate(e.value)}
            />
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Button
              type="button"
              label="Filter"
              style={{
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 14,
                backgroundColor: NewTheme.MainColor,
                borderWidth: 0,
                color: "white",
              }}
              onClick={onSubmit}
              data-pr-tooltip="SUBMIT"
            />
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Button
              type="button"
              label="XLS"
              severity="success"
              style={{
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 12,
              }}
              onClick={exportExcel}
              data-pr-tooltip="EXCEL"
            />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: 10,
            gap: 10,
          }}
        >
          <InputText
            style={{ width: 200 }}
            type="search"
            value={utrNo || ""}
            onChange={(e) => setUtrNo(e.target.value)}
            placeholder="Search By UTR"
          />
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Button
              type="button"
              label="SUBMIT"
              severity="info"
              style={{
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 12,
              }}
              onClick={onSubmit}
              data-pr-tooltip="EXCEL"
            />
          </div>
          <InputText
            style={{ width: 200 }}
            type="search"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Search"
          />
        </div>
      </div>
      <div id={"stick"} style={{ position: "relative", width: width }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 160}
          size="small"
          filters={filters}
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={!loading && tableData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={[
            "admin_username",
            "users_username",
            "service_type",
            "amount",
            "bank_name",
            "utr_no",
            "site_name",
            "client",
            "client_bank",
          ]}
          paginator
          onFilter={(e) => setFilters(e.filters)}
          rows={50}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          <Column field="id" showFilterMenu={false} header="SR" sortable />
          <Column
            field="admin_username"
            showFilterMenu={false}
            header="Admin"
            filter
            sortable
            body={(rowData) => (
              <div
                style={{
                  color: NewTheme.MainColor,
                }}
              >
                {rowData.admin_username}
              </div>
            )}
          />
          <Column
            field="users_username"
            showFilterMenu={false}
            header="Master"
            sortable
            filter
            body={(rowData) => <div>{rowData.users_username}</div>}
          />

          <Column
            field="amount"
            sortable
            showFilterMenu={false}
            header="Amount"
            body={(rowData) => <div>{rowData.amount}</div>}
          />
          <Column
            field="bank_name"
            showFilterMenu={false}
            header="Bank"
            sortable
            style={{ width: "20%" }}
            body={(rowData) => <div>{rowData.bank_name}</div>}
          />
          <Column
            field="in_out"
            showFilterMenu={false}
            header="Type"
            sortable
            style={{ width: "20%" }}
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.in_out == 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.in_out == 0 ? "Deposit" : "Withdraw"}
              </div>
            )}
          />
          <Column
            field="utr_no"
            showFilterMenu={false}
            header="UTR"
            sortable
            style={{ width: "10%" }}
            body={(rowData) => <div>{rowData.utr_no}</div>}
          />

          <Column
            field="site_name"
            showFilterMenu={false}
            header="Site"
            sortable
            style={{ width: "10%" }}
            body={(rowData) => <div>{rowData.site_name}</div>}
          />

          <Column
            field="client"
            showFilterMenu={false}
            header="Client"
            sortable
            style={{ width: "20%" }}
            body={(rowData) => <div>{rowData.client}</div>}
          />

          <Column
            field="client_bank"
            showFilterMenu={false}
            header="Client Bank"
            sortable
            style={{ width: "30%" }}
            body={(rowData) => (
              <div style={{ fontSize: 12 }}>{rowData.client_bank}</div>
            )}
          />

          <Column
            field="remarks"
            showFilterMenu={false}
            header="Remarks"
            sortable
            style={{ width: "20%" }}
            body={(rowData) => <div>{rowData.remarks}</div>}
          />

          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            sortable
            header="Date Created"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM-YY ")}
                <span style={{ color: "gray" }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
          />

          <Column
            field=""
            body={(rowData) => (
              <div style={{ width: 50 }}>
                <Button
                  type="button"
                  icon={"pi pi-trash"}
                  severity="danger"
                  onClick={() => delete_entry(rowData.id)}
                  style={{ height: 25, width: 25 }}
                />
              </div>
            )}
            showFilterMenu={false}
            header="Operate"
          />
        </DataTable>
      </div>
    </>
  );
}
