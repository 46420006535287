const api_link = "https://service.postick.co.in/Apis/minion_api.php";
const access_key = "Unnati@123";

export default class Php {
  login(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        login: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_bank_withdraw(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_bank_withdraw: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_bank_deposit(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_bank_deposit: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        edit_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_withdraw(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_withdraw: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_dashboard(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_dashboard: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_entry_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_entry_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_transaction_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_transaction_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_entry_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_entry_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_transaction_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_transaction_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_withdraw_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_withdraw_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_deposit_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_deposit_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_active_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_active_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        edit_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_site(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_site: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_entry(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_entry: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        edit_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_deposit_home(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_deposit_home: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_withdraw_home(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_withdraw_home: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_transaction(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_transaction: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_withdraw_transaction(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_withdraw_transaction: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_deposit_transaction(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_deposit_transaction: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_deposit(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_deposit: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_deposit_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_deposit_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_withdraw_bank(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_withdraw_bank: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }
}
